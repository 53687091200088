






























import { Component, Vue, Watch } from 'vue-property-decorator'
import TitleBase from '@/components/atoms/TitleBase.vue'
import TitleTextBase from '@/components/atoms/TitleTextBase.vue'
import TabBase from '@/components/atoms/TabBase.vue'
import SelectMultiple from '@/components/atoms/SelectMultiple.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'

@Component({
  components: {
    TitleBase,
    TitleTextBase,
    TabBase,
    SelectMultiple,
    ButtonBase,
  },
})
export default class SchoolTestUnit extends Vue {
  private schoolId = this.$route.params.id
  private termExamId = this.$route.params.subid
  private termId = this.$route.params.termId

  private isProcessing = false

  private breadcrumbs = [
    { text: '設定', href: '/teacher/setting/top' },
    { text: '学校登録', href: '/teacher/setting/school' },
    { text: '', href: '' },
    { text: '項目指定', active: true },
  ]

  private tabBaseDatas: { id: number; name: string }[] = []

  private subjectId = 0

  private studyUnits: { value: string; text: string }[] = []

  private targetUnits: { value: string; text: string }[] = []

  private selectedUnits: string[] = []

  private selectedTargetUnits: string[] = []

  private gradeId = 1

  private gradeCode = ''

  // 変更予約モードの分岐フラグ
  private isReserveMode = false

  @Watch('gradeId')
  onGradeIdChanged() {
    this.loadChangedStudyUnits()
  }

  private async loadChangedStudyUnits() {
    Vue.prototype.$loading.start()
    await this.loadStudyUnits(this.subjectId, null)
    Vue.prototype.$loading.complete()
  }

  private get colortype(): string {
    return this.targetUnits.length > 0 ? 'blue' : 'pointer-events-none'
  }

  private get prevPage(): string {
    return `/teacher/setting/school-test/${this.schoolId}?termId=${this.termId}#${this.gradeCode}`
  }

  private addUnit(): void {
    this.selectedUnits.forEach((unit_value: string) => {
      const obj = this.studyUnits.find((unit: { value: string; text: string }) => {
        return unit.value === unit_value
      })
      if (!obj) return
      this.targetUnits.push(Object.assign({}, obj))
      this.$set(obj, 'visible', false)
    })
  }
  private removeUnit(): void {
    this.selectedTargetUnits.forEach((unit_value: string) => {
      const obj = this.studyUnits.find((unit: { value: string; text: string }) => {
        return unit.value === unit_value
      })
      if (!obj) return
      this.targetUnits = this.targetUnits.filter((unit: { value: string; text: string }) => {
        return unit.value !== unit_value
      })
      this.$set(obj, 'visible', true)
    })
  }
  private upUnit(): void {
    // 一番上の項目選択時は動作しない
    if (this.selectedTargetUnits.includes(this.targetUnits[0].value)) return
    this.selectedTargetUnits.forEach((unit_value: string) => {
      const idx = this.targetUnits.findIndex((unit: { value: string; text: string }) => {
        return unit.value === unit_value
      })
      this.targetUnits = this.reverseTargetUnits(idx, idx - 1)
    })
  }
  private downUnit(): void {
    // 一番下の項目選択時は動作しない
    if (this.selectedTargetUnits.includes(this.targetUnits[this.targetUnits.length - 1].value)) return
    this.selectedTargetUnits.forEach((unit_value: string) => {
      const idx = this.targetUnits.findIndex((unit: { value: string; text: string }) => {
        return unit.value === unit_value
      })
      this.targetUnits = this.reverseTargetUnits(idx, idx + 1)
    })
  }

  private reverseTargetUnits(from_idx: number, to_idx: number): { value: string; text: string }[] {
    const result: { value: string; text: string }[] = []
    this.targetUnits.forEach((unit: { value: string; text: string }, index) => {
      if (index === from_idx) result.push(this.targetUnits[to_idx])
      else if (index === to_idx) result.push(this.targetUnits[from_idx])
      else result.push(unit)
    })
    return result
  }

  private filterStudyUnits(): void {
    this.targetUnits.forEach((t_unit: { value: string; text: string }) => {
      const obj = this.studyUnits.find((unit: { value: string; text: string }) => {
        return unit.value === t_unit.value
      })
      if (!obj) return
      this.$set(obj, 'visible', false)
    })
  }

  private async loadSchoolTestUnit() {
    await Vue.prototype.$http.httpWithToken.get(`/grades`).then((res: any) => {
      this.tabBaseDatas = res.data.map((grade: { id: number; name: string }) => {
        return {
          id: grade.id,
          name: grade.name,
        }
      })
    })

    await Vue.prototype.$http.httpWithToken.get(`/term_exams/${this.termExamId}/setting`).then((res: any) => {
      this.breadcrumbs[this.breadcrumbs.length - 2].text = res.data.termExam.schoolName
      this.breadcrumbs[
        this.breadcrumbs.length - 2
      ].href = `/teacher/setting/school-test/${this.schoolId}?termId=${this.termId}#${res.data.termExam.gradeCode}`
      this.subjectId = res.data.termExam.subjectId
      this.isReserveMode = res.data.isReserveMode
      this.gradeId = res.data.termExam.gradeId
      this.gradeCode = res.data.termExam.gradeCode
      let selectedUnits: [] | null = null
      if (res.data.settingIsItem && res.data.settings.length > 0) {
        selectedUnits = res.data.settings.map(
          (setting: {
            grade: string
            startPage: number
            endPage: number
            sUnitName: string
            publisherName: string
            publisherCurriculumsSUnitId: number
          }) => {
            return {
              value: setting.publisherCurriculumsSUnitId.toString(),
              text: `${setting.grade} ${setting.sUnitName}(${setting.publisherName}: p${setting.startPage}-p${setting.endPage})`,
            }
          }
        )
      }
      this.loadStudyUnits(res.data.termExam.subjectId, selectedUnits)
    })
  }

  private async createSchoolTestUnit() {
    Vue.prototype.$loading.start()
    await this.postTermExamsSetting()
    Vue.prototype.$loading.complete()
  }

  private async loadStudyUnits(subjectId: number, selectedUnits: any) {
    const params = [`schoolId=${this.schoolId}`, `gradeId=${this.gradeId}`, `subjectId=${subjectId}`]

    await Vue.prototype.$http.httpWithToken
      .get(`/publisher_curriculums/curriculum_s_units?${params.join('&')}`)
      .then((res: any) => {
        this.studyUnits = res.data.units.map(
          (unit: { id: number; sUnitName: string; name: string; startPage: number; endPage: number }) => {
            return {
              value: unit.id.toString(),
              text: `${this.getGradeName()} ${unit.sUnitName}(${unit.name}: p${unit.startPage}-p${unit.endPage})`,
            }
          }
        )
        if (selectedUnits) {
          this.targetUnits = selectedUnits
        }
      })
      .finally(() => {
        this.filterStudyUnits()
      })
  }

  private getGradeName(): string {
    const grade = this.tabBaseDatas.find((tabBaseData) => {
      return tabBaseData.id === this.gradeId
    })
    if (!grade) return ''
    return grade.name
  }

  private async postTermExamsSetting() {
    if (this.isProcessing) return
    this.isProcessing = true

    const settings = this.targetUnits.map((unit: { value: string; text: string }) => {
      return {
        publisherCurriculumsSUnitId: parseInt(unit.value),
      }
    })

    const apiUrl = this.isReserveMode
      ? `/term_exams/reserve/${this.termExamId}/setting`
      : `/term_exams/${this.termExamId}/setting`

    await Vue.prototype.$http.httpWithToken
      .post(apiUrl, { is_item: true, settings: settings })
      .then(() => {
        alert('登録しました。一覧に戻ります')
      })
      .catch((error: any) => {
        alert('エラーが発生しました。一覧に戻ります')
      })
      .finally(() => {
        this.$router.push({
          path: `/teacher/setting/school-test/${this.schoolId}`,
          query: { termId: this.termId.toString() },
          hash: this.gradeCode,
        })
        this.isProcessing = false
      })
  }

  private async mounted() {
    Vue.prototype.$loading.start()
    await this.loadSchoolTestUnit()
    Vue.prototype.$loading.complete()
  }
}
